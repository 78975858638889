<template>
    <CompTable ref="comp_table" title="居民数据导出工单管理" :columns="columns" :table-api="tableApi"></CompTable>
</template>
<script>
import CompTable from "../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            columns: [
                {
                    title: "申请人",
                    minWidth: 300,
                    key: "orgName",
                    align: "center",
                },
                {
                    title: "申请导出报表",
                    minWidth: 300,
                    key: "orgName",
                    align: "center",
                },
                {
                    title: "申请时间",
                    minWidth: 300,
                    key: "orgName",
                    align: "center",
                },
                {
                    title: "用途",
                    minWidth: 300,
                    key: "orgName",
                    align: "center",
                },
                {
                    title: "审核状态",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        let status = parseInt(params.row.status || "0")
                        return h(
                            "p",
                            {
                                style: {
                                    color: status === 1 ? "" : "red",
                                },
                            },
                            ["待审核", "审核通过", "审核失败", "锁定", "隐藏"][status] || "-"
                        )
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    minWidth: 150,
                    render: (h, params) => {
                        return [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: (params.row.status || 0) == 1 ? "" : "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.audit.load(params.row.detailId)
                                        },
                                    },
                                },
                                (params.row.status || 0) == 1 ? "查看" : "审核"
                            ),
                        ]
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
